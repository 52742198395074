<template>
   <div class="cont">
      <Breadcrumb :crumbs="crumbs"/>
      <el-row class="card-list" type="flex" justify="space-between">
         <el-row class="card-item" type="flex" align="middle">
            <img src="@/assets/dashboard-icon1.png" alt="">
            <span><p>新增客户</p><p>154</p></span>
         </el-row>
         <el-row class="card-item" type="flex" align="middle">
            <img src="@/assets/dashboard-icon2.png" alt="">
            <span><p>流失客户</p><p>4343</p></span>
         </el-row>
         <el-row class="card-item" type="flex" align="middle">
            <img src="@/assets/dashboard-icon3.png" alt="">
            <span><p>净增客户</p><p>121</p></span>
         </el-row>
         <el-row class="card-item" type="flex" align="middle">
            <img src="@/assets/dashboard-icon4.png" alt="">
            <span><p>累计客户</p><p>153</p></span>
         </el-row>
      </el-row>
      <div class="chart-box">
         <div id="chart"></div>
      </div>
   </div>
</template>
<script>
export default {
   data() {
      return {
         crumbs: new Map([
             ['数据中台'], ['企业微信'], ['数据统计']
         ]),
      }
   },
   mounted() {
      this.setChart()
   },
   methods: {
      setChart() {
         const option = {
            xAxis: {
               type: 'category',
               data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
               type: 'value'
            },
            series: [
               {
                  data: [150, 230, 224, 218, 135, 147, 260],
                  type: 'line'
               }
            ]
         };
         this.$echarts.init(document.getElementById('chart')).setOption(option)
      }
   }
}
</script>
<style lang="scss" scoped>
.cont {
  .card-list{
     background: #FFFFFF; padding: 30px 90px;
     $gradient-blue: linear-gradient(90deg, #006FFF, #189AFF);
     $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
     $gradient-red: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
     $gradient-yellow: linear-gradient(90deg, rgb(255, 161, 50), rgb(255, 209, 50));
     $list-bg: $gradient-blue, $gradient-green, $gradient-red, $gradient-yellow;
     .card-item {
       border-radius: 10px; padding: 10px 60px; color: #FFFFFF;
       @each $c in $list-bg {
         $i: index($list-bg, $c);
         &:nth-child(#{$i}) { background: nth($list-bg, $i); }
       }
     }
   }
  .chart-box{
    margin-top: 20px; background: #FFFFFF;
    #chart{ width: 100%; height: 500px }
  }
}
</style>
